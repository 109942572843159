
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonCard,
  IonCardContent,
  IonGrid,
  IonRow,
  IonCol,
} from '@ionic/vue';
import {defineComponent, ref} from 'vue';
import {logoLinkedin, logoGithub} from 'ionicons/icons';

import CodersrankWorkExperience from '@codersrank/work-experience';
import CodersrankActivity from '@codersrank/activity';
import CodersrankTimeline from '@codersrank/timeline';
import CodersrankSkillsChart from '@codersrank/skills-chart';

window.customElements.define('codersrank-skills-chart', CodersrankSkillsChart);
window.customElements.define('codersrank-activity', CodersrankActivity);

function calculateWorkExperienceColumns(): number {
  console.log(`calculating: ${window.innerWidth} ${window.innerWidth < 992 ? 1:2}`);
  return window.innerWidth < 992 ? 1 : 2
}

const workExperienceColumns = ref(calculateWorkExperienceColumns());
window.addEventListener('resize', () => {
  workExperienceColumns.value = calculateWorkExperienceColumns();
})

export default defineComponent({
  name: 'Home',
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonCard,
    IonCardContent,
    IonGrid,
    IonRow,
    IonCol,
  },
  setup() {
    return {
      workExperienceColumns,
      CodersrankActivity,
      CodersrankTimeline,
      CodersrankSkillsChart,
      CodersrankWorkExperience,
      logoGithub,
      logoLinkedin,
    }
  }
});
